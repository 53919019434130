import isEqual from 'lodash/isEqual'

function normalizeRouterQuery(value) {
  return Object.fromEntries(
    Object.entries(value)
      // Remove undefined or empty arrays
      .filter(([key, value]) => !(value === undefined || (Array.isArray(value) && value.length === 0)))
      // Ensure every value is made into an array
      .map(([key, value]) => [key, Array.isArray(value) ? value.map((value) => String(value)) : [String(value)]])
  )
}

/**
 * Returns true when the two inputs are equal, taking into account that the router sometimes stores values in an array
 *
 * For example:
 * { foo: "Foo", bar: 42, unspecified: undefined, empty: [] } is equal to { foo: ["Foo"], bar: ["42"] }
 */
export function routerQueryIsEqual(a, b) {
  return isEqual(normalizeRouterQuery(a), normalizeRouterQuery(b))
}
