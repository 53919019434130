import { render, staticRenderFns } from "./Pagination.vue?vue&type=template&id=38f99dde&scoped=true"
import script from "./Pagination.vue?vue&type=script&lang=js"
export * from "./Pagination.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38f99dde",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconChevronLeft: require('/home/userapp/components/Atom/Icon/IconChevronLeft.vue').default,IconChevronRight: require('/home/userapp/components/Atom/Icon/IconChevronRight.vue').default})
