
import { computed, defineComponent, unref, useRoute, watch } from '@nuxtjs/composition-api'
import { setupTranslationPrefixer } from '~/assets/translation'

export default defineComponent({
  props: {
    count: { type: Number, required: true, validator: (page) => page >= 0 },
    limit: { type: Number, required: true, validator: (page) => page > 0 },
    page: { type: Number, required: true, validator: (page) => page > 0 },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const route = useRoute()
    const highestPage = computed(() => Math.ceil(props.count / props.limit))
    const currentPage = computed(() => Math.min(unref(highestPage), props.page))
    const enablePrevious = computed(() => unref(currentPage) >= 2)
    const enableNext = computed(() => unref(currentPage) < unref(highestPage))
    const links = computed(() => {
      const _currentPage = unref(currentPage)
      const _highestPage = unref(highestPage)
      if (_highestPage < 6) {
        // only the first 5 links are needed
        return [...Array(_highestPage).keys()].map((offset) => ({ type: 'link', page: offset + 1 }))
      } else if (_currentPage > 3 && _currentPage < _highestPage - 2) {
        // replace links with dots at the beginning and end
        return [{ type: 'dots' }, ...[-1, 0, 1].map((offset) => ({ type: 'link', page: _currentPage + offset })), { type: 'dots' }]
      } else if (_currentPage <= _highestPage / 2) {
        // replace links with dots at the end
        return [...[1, 2, 3, 4].map((offset) => ({ type: 'link', page: offset })), { type: 'dots' }]
      } else {
        // replace links with dots at the beginning
        return [{ type: 'dots' }, ...[3, 2, 1, 0].map((offset) => ({ type: 'link', page: _highestPage - offset }))]
      }
    })

    // Emit a 'click' event to switch to a different page
    const onClick = (targetPage) => {
      if (targetPage && targetPage !== unref(currentPage)) {
        emit('click', { page: Math.max(1, Math.min(unref(highestPage), unref(targetPage))) })
        window.scrollTo(0, 0)
      }
    }

    // Emit a 'click' event when one of the query parameters changes, and we are on a page higher than 1,
    // we need to go back to page 1 since we do not know how many results the new results have
    watch(route, (to, from) => {
      // Bail when we change to a completely different route
      if (to.name !== from.name) {
        return
      }

      // Bail when we changed the page number
      const normalizedToPage = Number.parseInt(Array.isArray(to.query.page) ? to.query.page[0] : to.query.page) || 1
      const normalizedFromPage = Number.parseInt(Array.isArray(from.query.page) ? from.query.page[0] : from.query.page) || 1
      if (normalizedToPage !== normalizedFromPage) {
        return
      }

      // Bail when we changed to page number 1
      if (normalizedToPage === 1) {
        return
      }

      // Emit the event to trigger a page change
      emit('click', { page: 1 })
      window.scrollTo(0, 0)
    })

    /**
     * Returns an url to a specific page, useful for crawlers
     * @param page Page number
     * @returns string}
     */
    const getHref = (page) => {
      const query = Object.entries({ ...route.value?.query, page })
        .filter(([_, value]) => !(value === undefined || value === ''))
        .filter(([key, value]) => !(key === 'page' && value === 1))
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      return `${route.value?.path}${query.length ? `?${query.join('&')}` : ''}`
    }

    return {
      ...setupTranslationPrefixer('Pagination'),
      currentPage,
      enableNext,
      enablePrevious,
      getHref,
      highestPage,
      links,
      onClick,
    }
  },
})
